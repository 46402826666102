import request from "@/utils/request";
const controller = "/Common";

const getShareQr = (data) => request({
  url: `${controller}/Share`,
  method: "post",
  data
});
const getEnterpriseCondition = () => request({
  url: `${controller}/GetEnterpriseCondition`,
  method: 'GET'
});
const getJobCondition = () => request({
  url: `${controller}/GetJobCondition`,
  method: 'GET'
});
const getAreaList = () => request({
  url: `${controller}/GetAreaList`,
  method: 'GET'
});
const getSalaryList = () => request({
  url: `${controller}/GetSalaryList`,
  method: 'GET'
});
const getExperienceList = () => request({
  url: `${controller}/GetExperienceList`,
  method: 'GET'
});
const getEducationList = () => request({
  url: `${controller}/GetEducationList`,
  method: 'GET'
});
const getJobCategoryList = () => request({
  url: `${controller}/GetJobCategoryList`,
  method: 'GET'
});
const getMajorList = () => request({
  url: `${controller}/GetMajorList`,
  method: 'GET'
});

export {
  getShareQr,
  getEnterpriseCondition,
  getJobCondition,
  getAreaList,
  getSalaryList,
  getExperienceList,
  getEducationList,
  getJobCategoryList,
  getMajorList
}