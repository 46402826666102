import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";

Vue.use(VueRouter);
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const RouterView = {
  render: h => h("router-view")
};
const routes = [
  {
    path: "/",
    component: () => import("@/components/MainLayout.vue"),
    meta: {
      title: "广东轻工职业技术学院",
      isPublic: true
    },

    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("@/views/home/index.vue"),
        meta: {
          title: "首页",
          isPublic: true
        }
      },
      {
        path: "service",
        name: "Service",
        component: () => import("@/views/service/index.vue")
      },
      {
        path: "job",
        name: "Job",
        component: RouterView,
        children: [
          {
            path: "detail",
            name: "JobDetail",
            meta: {
              title: "职位详情",
              isPublic: true
            },
            component: () => import("@/views/job/detail.vue")
          }
        ]
      },
      {
        path: "course",
        name: "course",
        component: () => import("@/views/course/seartch.vue"),
        meta: {
          isPublic: true
        }
      },
      {
        path: "account",
        name: "account",
        component: () => import("@/views/account/index.vue")
      },
      {
        path: "information",
        // name: "Information",
        component: () => import("@/views/information/layout.vue"),
        children: [
          {
            path: "",
            name: "InformationIndex",
            meta: {
              title: "资讯动态",
              isPublic: true
            },
            component: () => import("@/views/information/index.vue")
          },
          {
            path: "detail",
            name: "InformationDetail",
            meta: {
              title: "发现",
              isPublic: true
            },
            component: () => import("@/views/information/detail.vue")
          }
        ]
      },
      {
        path: "cooperation",
        name: "Cooperation",
        redirect: "cooperation/enterprise",
        component: RouterView,
        children: [
          {
            path: "enterprise",
            name: "CooperationEnterprise",
            component: () => import("@/views/cooperation/index.vue"),
            meta: {
              isPublic: true,
              title: "企业"
            }
          },
          {
            path: "school",
            name: "CooperationSchool",
            component: () => import("@/views/cooperation/index.vue"),
            meta: {
              isPublic: true,
              title: "学校"
            }
          },
          {
            path: "home-enterprise",
            name: "CooperationHomeEnterprise",
            redirect: "home-enterprise/info",
            component: RouterView,
            children: [
              {
                path: "info",
                name: "CooperationHomeEnterpriseInfo",
                component: () => import("@/views/cooperation/enterprise.vue"),
                meta: {
                  isPublic: true,
                  title: "企业信息"
                }
              },
              {
                path: "post",
                name: "CooperationHomeEnterprisePost",
                component: () => import("@/views/cooperation/enterprise.vue"),
                meta: {
                  isPublic: true,
                  title: "岗位信息"
                }
              },
              {
                path: "post-detail",
                name: "CooperationHomeEnterprisePostDetail",
                component: () => import("@/views/cooperation/post/detail.vue"),
                meta: {
                  isPublic: true,
                  title: "企业简介"
                }
              }
            ]
          },
          {
            path: "home-school",
            name: "CooperationHomeSchool",
            redirect: "home-school/info",
            component: RouterView,
            children: [
              {
                path: "info",
                name: "CooperationHomeSchoolInfo",
                component: () => import("@/views/cooperation/school.vue"),
                meta: {
                  isPublic: true,
                  title: "院校简介"
                }
              },
              {
                path: "enrollment",
                name: "CooperationHomeSchoolRecruit",
                component: () => import("@/views/cooperation/school.vue"),
                meta: {
                  isPublic: true,
                  title: "招生简章"
                }
              },
              {
                path: "partner",
                name: "CooperationHomeSchoolPartner",
                component: () => import("@/views/cooperation/school.vue"),
                meta: {
                  isPublic: true,
                  title: "合作企业"
                }
              }
            ]
          }
        ]
      },
      {
        path: "recruitment",
        name: "Recruitment",
        redirect: "recruitment/recruit",
        meta: {
          title: "岗位招聘",
          isPublic: true
        },
        component: RouterView,
        children: [
          {
            path: "enrollment",
            name: "RecruitmentEnrollment",
            meta: {
              title: "岗位招聘",
              isPublic: true
            },
            component: () => import("@/views/recruitment/index.vue")
          },
          // {
          //   path: "enrollment-detail",
          //   name: "RecruitmentEnrollmentDetail",
          //   meta: {
          //     title: "招生简章",
          //     isPublic: true
          //   },
          //   component: () => import("@/views/recruitment/enrollment-detail.vue")
          // },
          {
            path: "recruit",
            name: "RecruitmentRecruit",
            meta: {
              title: "招生动态",
              isPublic: true
            },
            component: () => import("@/views/recruitment/index.vue")
          }
          // {
          //   path: "recruit-detail",
          //   name: "RecruitmentRecruitDetail",
          //   meta: {
          //     title: "招生动态",
          //     isPublic: true
          //   },
          //   component: () => import("@/views/recruitment/recruit-detail.vue")
          // }
        ]
      },
      {
        path: "seartchenterprise",
        name: "SeartchEnterprise",
        component: () => import("@/views/enterprise/seartch.vue")
      },
      {
        path: "seartchschool",
        name: "School",
        component: () => import("@/views/school/seartch.vue")
      },
      {
        path: "course",
        name: "Course",
        component: () => import("@/views/course"),
        children: [
          {
            path: "/CourseIndex",
            name: "CourseIndex",
            meta: {
              title: "课程资源",
              isPublic: true
            },
            component: () => import("@/views/course/seartch.vue")
          },
          {
            path: "detail",
            name: "CourseDetail",
            meta: {
              title: "课程详情",
              isPublic: true
            },
            component: () => import("@/views/course/detail.vue")
          }
        ]
      },
      {
        path: "cooperativeFirm",
        // name: "CooperativeFirm",
        component: () => import("@/views/cooperativeFirm"),
        meta: {
          isPublic: true
        },
        children: [
          {
            path: "",
            name: "CooperativeFirmIndex",
            meta: {
              title: "合作企业",
              isPublic: true
            },
            component: () => import("@/views/cooperativeFirm/FirmIndex.vue")
          },
          {
            path: "firmInfo",
            name: "FirmInfo",
            meta: {
              title: "公司详情",
              isPublic: true
            },
            component: () =>
              import("@/views/cooperativeFirm/components/school.vue")
          },
          {
            path: "ViewTutor",
            name: "ViewTutor",
            meta: {
              title: "教师列表",
              isPublic: true
            },
            component: () =>
              import(
                "@/views/cooperativeFirm/components/components/School/infoCard/Tutor"
              )
          },
          {
            path: "TutorDetail",
            name: "TutorDetail",
            meta: {
              title: "教师详情",
              isPublic: true
            },
            component: () =>
              import(
                "@/views/cooperativeFirm/components/components/School/infoCard/Tutor/TutorDetail.vue"
              )
          },
          {
            path: "CooperativeItem",
            name: "cooperativeItemList",
            meta: {
              title: "合作项目列表",
              isPublic: true
            },
            component: () =>
              import(
                "@/views/cooperativeFirm/components/components/School/infoCard/Item/index.vue"
              )
          },

          {
            path: "itemListInfo",
            name: "ProjectListInfo",
            meta: {
              title: "详情",
              isPublic: true
            },
            component: () =>
              import(
                "@/views/cooperativeFirm/components/components/School/infoCard/Detail"
              )
          },
          {
            path: "investmentItem",
            name: "InvestmentItemList",
            meta: {
              title: "投入资源列表",
              isPublic: true
            },
            component: () =>
              import(
                "@/views/cooperativeFirm/components/components/School/infoCard/Investment/index.vue"
              )
          },
          {
            path: "personnelTraining",
            name: "PersonnelTrainingList",
            meta: {
              title: "人才培养列表",
              isPublic: true
            },
            component: () =>
              import(
                "@/views/cooperativeFirm/components/components/School/infoCard/PersonnelTraining/index.vue"
              )
          },
          {
            path: "developmentPath",
            name: "DevelopmentPathList",
            meta: {
              title: "员工发展路径列表",
              isPublic: true
            },
            component: () =>
              import(
                "@/views/cooperativeFirm/components/components/School/infoCard/DevelopmentPath/index.vue"
              )
          },
          {
            path: "servesIndex",
            name: "ServesIndex",
            meta: {
              title: "产品服务列表",
              isPublic: true
            },
            component: () =>
              import(
                "@/views/cooperativeFirm/components/components/School/infoCard/Serve"
              )
          }
        ]
      },
      {
        path: "achievement",
        component: () => import("@/views/achievement/layout"),
        children: [
          {
            path: "",
            name: "AchievementIndex",
            meta: {
              title: "成果展示",
              isPublic: true
            },
            component: () => import("@/views/achievement/index.vue")
          },
          {
            path: "detail",
            name: "AchievementDetail",
            meta: {
              title: "成果详情",
              isPublic: true
            },
            component: () => import("@/views/achievement/detail.vue")
          }
        ]
      },
      {
        path: "arrangeEvaluate",
        // name: "Information",
        component:RouterView,
        children: [
          {
            path: "",
            name: "arrangeEvaluateIndex",
            meta: {
              title: "项目班评价",
              isPublic: true
            },
            component: () => import("@/views/arrangeEvaluate/index.vue"),
          }
        ]
      },
            // {
      //   path: "/enterprise/arrangeEvaluate",
      //   name: "ArrangeEvaluate",
      //   component: () => import("@/views/arrangeEvaluate/index.vue"),
      //   meta: {
      //     title: "评价项目班"
      //   }
      // },

      // 管理
      // {
      //   path: "/enterprise/practice/approve",
      //   component: () => import("@/components/PracticeLayout.vue"),
      //   children: [
      //     {
      //       path: "/enterprise/practice/approve",
      //       name: "ApproveEnterprise",
      //       component: () =>
      //         import("@/views/practice/practice-apply/approve/index.vue"),
      //       children: [
      //         {
      //           path: "/enterprise/practice/approve",
      //           name: "ApproveListEnterprise",
      //           component: () =>
      //             import("@/views/practice/practice-apply/approve/list.vue")
      //         },
      //         {
      //           path: "detail",
      //           name: "ApproveDetailEnterprise",
      //           component: () =>
      //             import("@/views/practice/practice-apply/approve/detail.vue")
      //         }
      //       ]
      //     }
      //   ]
      // }
    ]
  },
  {
    path: "/visual_analysis",
    // name: "Visual_analysis",
    component: () => import("@/views/visual_analysis/visualLayout.vue"),
    meta: {
      // title: "运行监控",
      isPublic: true
    },
    children: [
      {
        path: "/",
        name: "Analysis",
        meta: {
          title: "运行监控",
          isPublic: true
        },
        component: () => import("@/views/visual_analysis/index.vue")
      },
      {
        path: "projectCase",
        name: "ProjectCase",
        meta: {
          title: "项目情况",
          isPublic: true
        },
        component: () => import("@/views/visual_analysis/projectCase.vue")
      },
      {
        path: "enterpriseInfo",
        name: "EnterpriseInfo",
        meta: {
          title: "企业信息监控",
          isPublic: true
        },
        component: () => import("@/views/visual_analysis/enterpriseInfo.vue")
      },
      {
        path: "studentInfo",
        name: "StudentInfo",
        meta: {
          title: "学生信息监控",
          isPublic: true
        },
        component: () => import("@/views/visual_analysis/studentInfo.vue")
      }
    ]
  },
  {
    path: "/callback",
    name: "callback",
    component: () => import("@/views/callback.vue"),
    meta: {
      isOidcCallback: true
    }
  },
  {
    path: "/person",
    name: "PersonIndex",
    component: () => import("@/components/PersonLayout.vue"),
    meta: {
      title: "广东轻工职业技术学院"
    },
    children: [
      {
        path: "home",
        name: "PersonHome",
        meta: {
          title: "广东轻工职业技术学院"
        },
        component: () => import("@/views/person/index.vue")
      },
      {
        path: "resume",
        name: "Resume",
        component: () => import("@/views/resume/index.vue")
      },
      {
        path: "chat",
        name: "Chat",
        component: () => import("@/views/chat/index.vue")
      },
      {
        path: "favorite",
        name: "Favorite",
        component: () => import("@/views/favorite/index.vue")
      },
      {
        path: "job",
        name: "SearchJob",
        component: () => import("@/views/job/seartch.vue")
      },
      {
        path: "/person/career",
        // name: "IndexJob",
        component: () => import("@/views/career/index.vue"),
        children: [
          {
            path: "/person/career",
            name: "CareerHome",
            component: () => import("@/views/career/home.vue")
          }
        ]
      }
    ]
  },
  {
    path: "/enterprise",
    component: () => import("@/components/EnterpriseLayout.vue"),
    meta: {
      title: "广东轻工职业技术学院"
    },
    children: [
      {
        path: "/enterprise",
        name: "EnterpriseHome",
        meta: {
          title: "企业中心"
        },
        component: () => import("@/views/enterprise/home.vue")
      },
      {
        path: "/enterprise/job",
        component: () => import("@/views/job/index.vue"),
        children: [
          {
            path: "/enterprise/job",
            name: "ListJobEnterprise",
            component: () => import("@/views/job/list.vue"),
            meta: {
              authorization: true,
              title: "岗位管理"
            }
          },
          {
            path: "create",
            name: "CreateJob",
            component: () => import("@/views/job/create.vue"),
            meta: {
              authorization: true,
              title: "岗位管理"
            }
          },
          {
            path: "seeker",
            name: "JobSeekerEnterprise",
            component: () => import("@/views/job/seeker"),
            meta: {
              authorization: true,
              title: "岗位管理"
            }
          },
          {
            path: "edit",
            name: "EditJobEnterprise",
            component: () => import("@/views/job/edit.vue"),
            meta: {
              title: "岗位管理"
            }
          },
          {
            path: "detail",
            name: "DetailJobEnterprise",
            component: () => import("@/views/job/detail.vue"),
            meta: {
              title: "岗位管理"
            }
          }
        ]
      },
      {
        path: "/enterprise/recruitnews",
        component: () => import("@/views/recruit-news/index.vue"),
        children: [
          {
            path: "/enterprise/recruitnews",
            name: "ListRecruitnews",
            component: () => import("@/views/recruit-news/list.vue"),
            meta: {
              authorization: true,
              title: "招生动态"
            }
          },
          {
            path: "create",
            name: "CreateRecruitnews",
            component: () => import("@/views/recruit-news/create.vue"),
            meta: {
              authorization: true,
              title: "招生动态"
            }
          },
          {
            path: "edit",
            name: "EditRecruitnews",
            component: () => import("@/views/recruit-news/edit.vue"),
            meta: {
              authorization: true,
              title: "招生动态"
            }
          },
          {
            path: "applylist",
            name: "ApplyList",
            component: () => import("@/views/recruit-news/apply-list.vue"),
            meta: {
              authorization: true,
              title: "报名信息"
            }
          }
        ]
      },
      {
        path: "/enterprise/course",
        component: () => import("@/views/course/index.vue"),
        children: [
          {
            path: "/enterprise/course",
            name: "ListCourseEnterprise",
            component: () => import("@/views/course/list.vue"),
            meta: {
              authorization: true,
              title: "课程资源"
            }
          },
          {
            path: "create",
            name: "CreateCourseEnterprise",
            component: () => import("@/views/course/create.vue"),
            meta: {
              authorization: true,
              title: "课程资源"
            }
          },
          {
            path: "edit",
            name: "EditCourseEnterprise",
            component: () => import("@/views/course/edit.vue"),
            meta: {
              authorization: true,
              title: "课程资源"
            }
          }
        ]
      },
      {
        path: "/enterprise/order",
        // name: "IndexJob",
        component: () => import("@/views/order/index.vue"),
        children: [
          {
            path: "/enterprise/order",
            name: "ListOrder",
            component: () => import("@/views/order/list.vue")
          },
          {
            path: "create",
            name: "CreateOrder",
            component: () => import("@/views/order/create.vue")
          },
          {
            path: "seeker",
            name: "OrderSeeker",
            component: () => import("@/views/order/seeker.vue")
          }
        ]
      },
      {
        path: "talent",
        name: "EnterpriseTalent",
        component: () => import("@/views/talent/index.vue"),
        meta: {
          authorization: true,
          title: "人才搜索"
        }
      },
      {
        path: "update",
        name: "EnterpriseUpdate",
        component: () => import("@/views/enterprise/update.vue"),
        meta: {
          authorization: true,
          title: "企业信息修改"
        }
      },
      {
        path: "chat",
        name: "EnterpriseChat",
        component: () => import("@/views/chat/index.vue"),
        meta: {
          authorization: true,
          title: "在线沟通"
        }
      },
      {
        path: "favorite",
        name: "EnterpriseFavorite",
        component: () => import("@/views/favorite/enterprise.vue")
      },
      {
        path: "/enterprise/school",
        // name: "EnterpriseList",
        component: () => import("@/views/enterprise/school/index.vue"),
        meta: {
          authorization: true
        },
        children: [
          {
            path: "/enterprise/school",
            name: "SchoolList",
            meta: {
              title: "合作院校"
            },
            component: () => import("@/views/enterprise/school/list.vue")
          },
          {
            path: "create",
            name: "CreateSchool",
            component: () => import("@/views/enterprise/school/create.vue")
          },
          {
            path: "edit",
            name: "EditSchool",
            component: () => import("@/views/enterprise/school/edit.vue")
          },
          {
            path: "detail",
            name: "DetailSchool",
            component: () => import("@/views/enterprise/school/detail.vue")
          }
        ]
      },
      {
        path: "/enterprise/cooperation",
        // name: "EnterpriseList",
        component: () => import("@/views/enterprise/cooperation/index.vue"),
        meta: {
          authorization: true
        },
        children: [
          {
            path: "/enterprise/cooperation",
            name: "CooperationList",
            meta: {
              title: "客户管理"
            },
            component: () => import("@/views/enterprise/cooperation/list.vue")
          },
          {
            path: "create",
            name: "CreateCooperation",
            component: () => import("@/views/enterprise/cooperation/create.vue")
          },
          {
            path: "edit",
            name: "EditCooperation",
            component: () => import("@/views/enterprise/cooperation/edit.vue")
          },
          {
            path: "detail",
            name: "DetailCooperation",
            component: () => import("@/views/enterprise/cooperation/detail.vue")
          }
        ]
      },
      // 注释2
      {
        path: "/enterprise/practice/approve",
        component: () => import("@/components/PracticeLayout.vue"),
        children: [
          {
            path: "/enterprise/practice/approve",
            name: "ApproveEnterprise",
            component: () =>
              import("@/views/practice/practice-apply/approve/index.vue"),
            children: [
              {
                path: "/enterprise/practice/approve",
                name: "ApproveListEnterprise",
                component: () =>
                  import("@/views/practice/practice-apply/approve/list.vue")
              },
              {
                path: "detail",
                name: "ApproveDetailEnterprise",
                component: () =>
                  import("@/views/practice/practice-apply/approve/detail.vue")
              }
            ]
          },
          {
            path: "/enterprise/practice/implement",
            name: "ImplementEnterprise",
            component: () =>
              import("@/views/practice/practice-apply/implement/index.vue"),
            children: [
              {
                path: "/enterprise/practice/implement",
                name: "ImplementListEnterprise",
                component: () =>
                  import("@/views/practice/practice-apply/implement/list.vue")
              }
            ]
          },
          {
            path: "/enterprise/practice/project",
            name: "ProjectEnterprise",
            component: () =>
              import("@/views/practice/practice-process/project/index.vue"),
            children: [
              {
                path: "/enterprise/practice/project",
                name: "ProjectListEnterprise",
                component: () =>
                  import("@/views/practice/practice-process/project/list.vue")
              },
              {
                path: "create",
                name: "CreateProjectEnterprise",
                component: () =>
                  import("@/views/practice/practice-process/project/create.vue")
              },
              {
                path: "edit",
                name: "EditProjectEnterprise",
                component: () =>
                  import("@/views/practice/practice-process/project/edit.vue")
              },
              {
                path: "view",
                name: "ViewProjectEnterprise",
                component: () =>
                  import("@/views/practice/practice-process/project/view.vue")
              }
            ]
          },
          {
            path: "/enterprise/practice/signrule",
            name: "SignRuleEnterprise",
            component: () =>
              import("@/views/practice/practice-process/signrule/index.vue"),
            children: [
              {
                path: "/enterprise/practice/signrule",
                name: "SignRuleListEnterprise",
                component: () =>
                  import("@/views/practice/practice-process/signrule/list.vue")
              },
              {
                path: "create",
                name: "CreateSignRuleEnterprise",
                component: () =>
                  import(
                    "@/views/practice/practice-process/signrule/create.vue"
                  )
              },
              {
                path: "edit",
                name: "EditSignRuleEnterprise",
                component: () =>
                  import("@/views/practice/practice-process/signrule/edit.vue")
              }
            ]
          },
          {
            path: "/enterprise/practice/score",
            name: "ScoreEnterprise",
            component: () =>
              import("@/views/practice/assessment/score/index.vue"),
            children: [
              {
                path: "/enterprise/practice/score",
                name: "ScoreListEnterprise",
                component: () =>
                  import("@/views/practice/assessment/score/list.vue")
              },
              {
                path: "edit",
                name: "EditScoreEnterprise",
                component: () =>
                  import("@/views/practice/assessment/score/edit.vue")
              },
              {
                path: "view",
                name: "ViewScoreEnterprise",
                component: () =>
                  import("@/views/practice/assessment/score/view.vue")
              }
            ]
          }
        ]
      },
      // 企业管理
      {
        path: "/enterprise/staff",
        component: () => import("@/components/EnterpriseLayout2.vue"),
        children: [
          {
            path: "/enterprise/staff",
            name: "ListEnterpriseStaff",
            // component: () => import("@/views/enterprise/staff/index.vue"),
            component: RouterView,
            meta: {
              title: "企业员工管理"
            },
            children: [
              {
                path: "/enterprise/staff",
                name: "ListEnterpriseStaffIndex",
                component: () => import("@/views/enterprise/staff/index.vue"),
                meta: {
                  title: "企业员工管理"
                }
              },
              {
                path: "create",
                name: "CreateEnterpriseStaff",
                component: () => import("@/views/enterprise/staff/create.vue"),
                meta: {
                  title: "新增员工"
                }
              },
              {
                path: "edit",
                name: "EditEnterpriseStaff",
                component: () => import("@/views/enterprise/staff/edit.vue"),
                meta: {
                  title: "修改员工"
                }
              },
              {
                path: "view",
                name: "ViewEnterpriseStaff",
                component: () => import("@/views/enterprise/staff/view.vue"),
                meta: {
                  title: "修改员工"
                }
              }
            ]
          },
          // 合作项目
          {
            path: "/enterprise/project",
            name: "ListEnterpriseProject",
            // component: () => import("@/views/enterprise/staff/index.vue"),
            component: RouterView,
            meta: {
              title: "企业项目管理"
            },
            children: [
              {
                path: "/enterprise/project",
                name: "ListEnterpriseProjectIndex",
                component: () => import("@/views/enterprise/project/index.vue"),
                meta: {
                  title: "企业项目管理"
                }
              },
              {
                path: "create",
                name: "CreateEnterpriseProject",
                component: () =>
                  import("@/views/enterprise/project/create.vue"),
                meta: {
                  title: "新增项目"
                }
              },
              {
                path: "edit",
                name: "EditEnterpriseProject",
                component: () => import("@/views/enterprise/project/edit.vue"),
                meta: {
                  title: "修改项目"
                }
              },
              {
                path: "view",
                name: "ViewEnterpriseProject",
                component: () => import("@/views/enterprise/project/view.vue"),
                meta: {
                  title: "查看项目"
                }
              }
            ]
          },
          // 投入资源
          {
            path: "/enterprise/resourceInvestment",
            name: "ListEnterpriseResourceInvestment",
            // component: () => import("@/views/enterprise/staff/index.vue"),
            component: RouterView,
            meta: {
              title: "企业资源管理"
            },
            children: [
              {
                path: "/enterprise/resourceInvestment",
                name: "ListEnterpriseResourceInvestmentIndex",
                component: () =>
                  import("@/views/enterprise/resourceInvestment/index.vue"),
                meta: {
                  title: "企业资源管理"
                }
              },
              {
                path: "create",
                name: "CreateEnterpriseResourceInvestment",
                component: () =>
                  import("@/views/enterprise/resourceInvestment/create.vue"),
                meta: {
                  title: "新增资源"
                }
              },
              {
                path: "edit",
                name: "EditEnterpriseResourceInvestment",
                component: () =>
                  import("@/views/enterprise/resourceInvestment/edit.vue"),
                meta: {
                  title: "修改资源"
                }
              },
              {
                path: "view",
                name: "ViewEnterpriseResourceInvestment",
                component: () =>
                  import("@/views/enterprise/resourceInvestment/view.vue"),
                meta: {
                  title: "查看资源"
                }
              }
            ]
          },
          // 人才培养方案
          {
            path: "/enterprise/personnelTraining",
            name: "ListEnterprisePersonnelTraining",
            // component: () => import("@/views/enterprise/staff/index.vue"),
            component: RouterView,
            meta: {
              title: "人才培养方案管理"
            },
            children: [
              {
                path: "/enterprise/personnelTraining",
                name: "ListEnterprisePersonnelTrainingIndex",
                component: () =>
                  import("@/views/enterprise/personnelTraining/index.vue"),
                meta: {
                  title: "人才培养方案管理"
                }
              },
              {
                path: "create",
                name: "CreateEnterprisePersonnelTraining",
                component: () =>
                  import("@/views/enterprise/personnelTraining/create.vue"),
                meta: {
                  title: "新增人才培养方案"
                }
              },
              {
                path: "edit",
                name: "EditEnterprisePersonnelTraining",
                component: () =>
                  import("@/views/enterprise/personnelTraining/edit.vue"),
                meta: {
                  title: "修改人才培养方案"
                }
              },
              {
                path: "view",
                name: "ViewEnterprisePersonnelTraining",
                component: () =>
                  import("@/views/enterprise/personnelTraining/view.vue"),
                meta: {
                  title: "查看人才培养方案"
                }
              }
            ]
          },
          // 企业发展路径
          {
            path: "/enterprise/developmentPath",
            name: "ListEnterpriseDevelopmentPath",
            // component: () => import("@/views/enterprise/staff/index.vue"),
            component: RouterView,
            meta: {
              title: "企业发展路径"
            },
            children: [
              {
                path: "/enterprise/developmentPath",
                name: "ListEnterpriseDevelopmentPathIndex",
                component: () =>
                  import("@/views/enterprise/developmentPath/index.vue"),
                meta: {
                  title: "企业发展路径管理"
                }
              },
              {
                path: "create",
                name: "CreateEnterpriseDevelopmentPath",
                component: () =>
                  import("@/views/enterprise/developmentPath/create.vue"),
                meta: {
                  title: "新增企业发展路径"
                }
              },
              {
                path: "edit",
                name: "EditEnterpriseDevelopmentPath",
                component: () =>
                  import("@/views/enterprise/developmentPath/edit.vue"),
                meta: {
                  title: "修改企业发展路径"
                }
              },
              {
                path: "view",
                name: "ViewEnterpriseDevelopmentPath",
                component: () =>
                  import("@/views/enterprise/developmentPath/view.vue"),
                meta: {
                  title: "查看企业发展路径"
                }
              }
            ]
          },
          // 产品服务
          {
            path: "/enterprise/productionService",
            name: "ListEnterpriseProductionService",
            // component: () => import("@/views/enterprise/staff/index.vue"),
            component: RouterView,
            meta: {
              title: "产品服务管理"
            },
            children: [
              {
                path: "/enterprise/productionService",
                name: "ListEnterpriseProductionServiceIndex",
                component: () =>
                  import("@/views/enterprise/productionService/index.vue"),
                meta: {
                  title: "产品服务管理"
                }
              },
              {
                path: "create",
                name: "CreateEnterpriseProductionService",
                component: () =>
                  import("@/views/enterprise/productionService/create.vue"),
                meta: {
                  title: "新增产品服务"
                }
              },
              {
                path: "edit",
                name: "EditEnterpriseProductionService",
                component: () =>
                  import("@/views/enterprise/productionService/edit.vue"),
                meta: {
                  title: "修改产品服务"
                }
              },
              {
                path: "view",
                name: "ViewEnterpriseProductionService",
                component: () =>
                  import("@/views/enterprise/productionService/view.vue"),
                meta: {
                  title: "查看产品服务"
                }
              }
            ]
          }
        ]
      },
      // {
      //   path: "/enterprise/arrangeEvaluate",
      //   name: "ArrangeEvaluate",
      //   component: () => import("@/views/arrangeEvaluate/index.vue"),
      //   meta: {
      //     title: "评价项目班"
      //   }
      // },
    ]
  },
  {
    path: "/school",
    component: () => import("@/components/SchoolLayout.vue"),
    meta: {
      title: "广东轻工职业技术学院"
    },
    children: [
      {
        path: "/school",
        name: "SchooleHome",
        // meta: {
        //   title: "广东轻工职业技术学院"
        // },
        component: () => import("@/views/school/home.vue"),
        meta: {
          authorization: true,
          title: "学校中心"
        }
      },

      {
        path: "/DCDataCentre",
        component: () => import("@/layout"),
        meta: {
          title: "院校管理"
        },
        children: [
          {
            path: "",
            name: "DCDataCentreIndex",
            meta: {
              title: "学校信息管理"
            },
            // component: () => import("@/views/school/DCDataCentre/index.vue")
            component: () => import("@/views/school/update.vue")
          },
          {
            path: "myAdmin",
            name: "Home",
            meta: {
              title: "基础数据管理平台"
            },
            component: () =>
              import("@/views/school/DCDataCentre/admin/index.vue")
          },
          {
            path: "info",
            name: "DCDataCentreInfo",
            meta: {
              title: "学校信息"
            },
            component: () => import("@/views/school/DCDataCentre/info.vue")
          },
          {
            path: "organization",
            name: "DCDataCentreOrganization",
            meta: {
              title: "组织架构"
            },
            component: () =>
              import("@/views/school/DCDataCentre/organization.vue")
          },
          {
            path: "leftTeacher",
            name: "DCDataCentreLeftTeacher",
            meta: {
              title: "教师管理"
            },
            component: () =>
              import("@/views/school/DCDataCentre/leftTeacher.vue")
          },
          {
            path: "leftStudent",
            name: "DCDataCentreLeftStudent ",
            meta: {
              title: "学生管理"
            },
            component: () =>
              import("@/views/school/DCDataCentre/leftStudent.vue")
          },
          {
            path: "teacher",
            component: RouterView,
            redirect: "teacher/index",
            meta: {
              title: "教师管理"
            },
            children: [
              {
                path: "index",
                name: "DCDataCentreTeacher",
                meta: {
                  title: "教师管理"
                },
                component: () =>
                  import("@/views/school/DCDataCentre/teacher/index.vue")
              },
              {
                path: "view",
                name: "DCDataCentreTeacherView",
                component: () =>
                  import("@/views/school/DCDataCentre/teacher/view.vue")
              },
              {
                path: "create",
                name: "DCDataCentreTeacherCreate",
                component: () =>
                  import("@/views/school/DCDataCentre/teacher/create.vue")
              },
              {
                path: "edit",
                name: "DCDataCentreTeacherEdit",
                component: () =>
                  import("@/views/school/DCDataCentre/teacher/edit.vue")
              },
              {
                path: "import",
                name: "DCDataCentreTeacherImport",
                component: () =>
                  import("@/views/school/DCDataCentre/teacher/import.vue")
              }
            ]
          },
          {
            path: "student",
            component: RouterView,
            redirect: "student/index",
            meta: {
              title: "学生管理"
            },
            children: [
              {
                path: "index",
                name: "DCDataCentreStudent",
                meta: {
                  title: "学生管理"
                },
                component: () =>
                  import("@/views/school/DCDataCentre/student/index.vue")
              },
              {
                path: "view",
                name: "DCDataCentreStudentView",
                component: () =>
                  import("@/views/school/DCDataCentre/student/view.vue")
              },
              {
                path: "create",
                name: "DCDataCentreStudentCreate",
                component: () =>
                  import("@/views/school/DCDataCentre/student/create.vue")
              },
              {
                path: "edit",
                name: "DCDataCentreStudentEdit",
                component: () =>
                  import("@/views/school/DCDataCentre/student/edit.vue")
              },
              {
                path: "import",
                name: "DCDataCentreStudentImport",
                component: () =>
                  import("@/views/school/DCDataCentre/student/import.vue")
              }
            ]
          }
        ]
      },
      {
        path: "/school/order",
        // name: "IndexJob",
        component: () => import("@/views/order/index.vue"),
        children: [
          {
            path: "/school/order",
            name: "SchoolListOrder",
            component: () => import("@/views/order/schoollist.vue")
          },
          {
            path: "mine",
            name: "SchoolListOrderMine",
            component: () => import("@/views/order/mylist.vue")
          },
          {
            path: "create",
            name: "OrderCreateJob",
            component: () => import("@/views/order/create.vue")
          }
        ]
      },
      {
        path: "/school/recruit",
        // name: "IndexJob",
        component: () => import("@/views/recruit/index.vue"),
        children: [
          {
            path: "/school/recruit",
            name: "ListRecruit",
            component: () => import("@/views/recruit/list.vue")
          },
          {
            path: "create",
            name: "RecruitCreateJob",
            component: () => import("@/views/recruit/create.vue")
          },
          {
            path: "studentlist",
            name: "StudentList",
            component: () => import("@/views/recruit/studentlist.vue")
          }
        ]
      },
      {
        path: "/school/course",
        component: () => import("@/views/course/index.vue"),
        children: [
          {
            path: "/school/course",
            name: "ListCourseSchool",
            component: () => import("@/views/course/list.vue"),
            meta: {
              authorization: true,
              title: "课程管理"
            }
          },
          {
            path: "create",
            name: "CreateCourseSchool",
            component: () => import("@/views/course/create.vue"),
            meta: {
              authorization: true,
              title: "课程管理"
            }
          },
          {
            path: "edit",
            name: "EditCourseSchool",
            component: () => import("@/views/course/edit.vue"),
            meta: {
              authorization: true,
              title: "课程管理"
            }
          }
        ]
      },
      // {
      //   path: "/school/arrangeEvaluate",
      //   name: "ArrangeEvaluate",
      //   component: () => import("@/views/arrangeEvaluate/index.vue"),
      //   meta: {
      //     title: "评价项目班"
      //   }
      // },
      {
        path: "update",
        name: "SchoolUpdate",
        component: () => import("@/views/school/update.vue"),
        meta: {
          authorization: true,
          title: "学校信息管理"
        }
      },
      {
        path: "enterprise",
        name: "SchoolEnterprise",
        component: () => import("@/views/enterprise/seartch.vue")
      },
      {
        path: "chat",
        name: "SchoolChat",
        component: () => import("@/views/chat/index.vue")
      },
      {
        path: "favorite",
        name: "SchoolFavorite",
        component: () => import("@/views/favorite/school.vue")
      },
      {
        path: "/school/enterpriselist",
        // name: "EnterpriseList",
        component: () => import("@/views/school/enterprise/index.vue"),
        meta: {
          authorization: true
        },
        children: [
          {
            path: "/school/enterpriselist",
            name: "EnterpriseList",
            component: () => import("@/views/school/enterprise/list.vue"),
            meta: {
              authorization: true,
              title: "合作企业"
            }
          },
          {
            path: "create",
            name: "CreateEnterprise",
            component: () => import("@/views/school/enterprise/create.vue"),
            meta: {
              authorization: true,
              title: "合作企业"
            }
          },
          {
            path: "edit",
            name: "EditEnterprise",
            component: () => import("@/views/school/enterprise/edit.vue"),
            meta: {
              authorization: true,
              title: "合作企业"
            }
          },
          {
            path: "detail",
            name: "DetailEnterprise",
            component: () => import("@/views/school/enterprise/detail.vue"),
            meta: {
              authorization: true,
              title: "合作企业"
            }
          }
        ]
      },
      {
        path: "/school/recruitnews",
        component: () => import("@/views/recruit-news/index.vue"),
        children: [
          {
            path: "/school/recruitnews",
            name: "SchoolListRecruitnews",
            component: () => import("@/views/recruit-news/list.vue"),
            meta: {
              authorization: true,
              title: "招生动态"
            }
          },
          {
            path: "create",
            name: "SchoolCreateRecruitnews",
            component: () => import("@/views/recruit-news/create.vue"),
            meta: {
              authorization: true,
              title: "招生动态"
            }
          },
          {
            path: "edit",
            name: "SchoolEditRecruitnews",
            component: () => import("@/views/recruit-news/edit.vue"),
            meta: {
              authorization: true,
              title: "招生动态"
            }
          },
          {
            path: "applylist",
            name: "SchoolApplyList",
            component: () => import("@/views/recruit-news/apply-list.vue"),
            meta: {
              authorization: true,
              title: "报名信息"
            }
          }
        ]
      },
      {
        path: "/school/enrollmentnews",
        component: () => import("@/views/enrollment-news/index.vue"),
        meta: {
          authorization: true
        },
        children: [
          {
            path: "/school/enrollmentnews",
            name: "ListEnrollmentnews",
            component: () => import("@/views/enrollment-news/list.vue"),
            meta: {
              authorization: true,
              title: "招生简章"
            }
          },
          {
            path: "create",
            name: "CreateEnrollmentnews",
            component: () => import("@/views/enrollment-news/create.vue"),
            meta: {
              authorization: true,
              title: "招生简章"
            }
          },
          {
            path: "edit",
            name: "EditEnrollmentnews",
            component: () => import("@/views/enrollment-news/edit.vue"),
            meta: {
              authorization: true,
              title: "招生简章"
            }
          },
          {
            path: "detail",
            name: "DetailEnrollmentnews",
            component: () => import("@/views/enrollment-news/detail.vue"),
            meta: {
              authorization: true,
              title: "招生简章"
            }
          }
        ]
      },
      // 学校-新闻管理
      {
        path: "/school/news-management",
        component: () => import("@/views/news-management/index.vue"),
        meta: {
          authorization: true
        },
        children: [
          {
            path: "/school/news-management",
            name: "ListNewsManagement",
            component: () => import("@/views/news-management/list.vue"),
            meta: {
              authorization: true,
              title: "新闻动态"
            }
          },
          {
            path: "create",
            name: "CreateNewsManagement",
            component: () => import("@/views/news-management/create.vue"),
            meta: {
              authorization: true,
              title: "新闻动态"
            }
          },
          {
            path: "edit",
            name: "EditNewsManagement",
            component: () => import("@/views/news-management/edit.vue"),
            meta: {
              authorization: true,
              title: "新闻动态"
            }
          },
          {
            path: "detail",
            name: "DetailNewsManagement",
            component: () => import("@/views/news-management/detail.vue"),
            meta: {
              authorization: true,
              title: "新闻动态"
            }
          }
        ]
      },
      // 学校_banner管理_banner-management_横幅管理
      {
        path: "/school/banner-management",
        component: () => import("@/views/banner-management/index.vue"),
        meta: {
          authorization: true
        },
        children: [
          {
            path: "/school/banner-management",
            name: "ListBannerManagement",
            component: () => import("@/views/banner-management/list.vue"),
            meta: {
              authorization: true,
              title: "横幅管理"
            }
          },
          {
            path: "create",
            name: "CreateBannerManagement",
            component: () => import("@/views/banner-management/create.vue"),
            meta: {
              authorization: true,
              title: "横幅管理"
            }
          },
          {
            path: "edit",
            name: "EditBannerManagement",
            component: () => import("@/views/banner-management/edit.vue"),
            meta: {
              authorization: true,
              title: "横幅管理"
            }
          },
          {
            path: "detail",
            name: "DetailBannerManagement",
            component: () => import("@/views/banner-management/detail.vue"),
            meta: {
              authorization: true,
              title: "横幅管理"
            }
          }
        ]
      },
      {
        path: "/school/achievementshow",
        component: () => import("@/views/achievement-show/index.vue"),
        meta: {
          authorization: true
        },
        children: [
          {
            path: "/school/achievementShow",
            name: "ListAchievementShow",
            component: () => import("@/views/achievement-show/list.vue"),
            meta: {
              authorization: true,
              title: "招生简章"
            }
          },
          {
            path: "create",
            name: "CreateAchievementShow",
            component: () => import("@/views/achievement-show/create.vue"),
            meta: {
              authorization: true,
              title: "招生简章"
            }
          },
          {
            path: "edit",
            name: "EditAchievementShow",
            component: () => import("@/views/achievement-show/edit.vue"),
            meta: {
              authorization: true,
              title: "招生简章"
            }
          },
          {
            path: "detail",
            name: "DetailAchievementShow",
            component: () => import("@/views/achievement-show/detail.vue"),
            meta: {
              authorization: true,
              title: "招生简章"
            }
          }
        ]
      },

      {
        path: "/school/job",
        component: () => import("@/views/job/index.vue"),
        children: [
          {
            path: "/school/job",
            name: "ListJob",
            component: () => import("@/views/job/list.vue"),
            meta: {
              authorization: true,
              title: "招工管理"
            }
          },
          {
            path: "create",
            name: "JobCreateJob",
            component: () => import("@/views/job/create.vue"),
            meta: {
              authorization: true,
              title: "招工管理"
            }
          },
          {
            path: "seeker",
            name: "JobSeekerSchool",
            component: () => import("@/views/job/seeker"),
            meta: {
              authorization: true,
              title: "招工管理"
            }
          },
          {
            path: "edit",
            name: "EditJob",
            component: () => import("@/views/job/edit.vue"),
            meta: {
              authorization: true,
              title: "招工管理"
            }
          },
          {
            path: "detail",
            name: "DetailJob",
            component: () => import("@/views/job/detail.vue"),
            meta: {
              authorization: true,
              title: "招工管理"
            }
          }
        ]
      },
      {
        path: "/school/practice/arrange",
        component: () => import("@/components/PracticeLayout.vue"),
        children: [
          {
            path: "/school/practice/arrange",
            name: "Arrange",
            component: () =>
              import("@/views/practice/practice-apply/arrange/index.vue"),
            children: [
              {
                path: "/school/practice/arrange",
                name: "ArrangeList",
                component: () =>
                  import("@/views/practice/practice-apply/arrange/list.vue")
              },
              {
                path: "create",
                name: "CreateArrange",
                component: () =>
                  import("@/views/practice/practice-apply/arrange/create.vue")
              },
              {
                path: "edit",
                name: "EditArrange",
                component: () =>
                  import("@/views/practice/practice-apply/arrange/edit.vue")
              },
              {
                path: "view",
                name: "ViewArrange",
                component: () =>
                  import("@/views/practice/practice-apply/arrange/view.vue")
              }
            ]
          },
          {
            path: "/school/practice/approve",
            name: "Approve",
            component: () =>
              import("@/views/practice/practice-apply/approve/index.vue"),
            children: [
              {
                path: "/school/practice/approve",
                name: "ApproveList",
                component: () =>
                  import("@/views/practice/practice-apply/approve/list.vue")
              },
              {
                path: "detail",
                name: "ApproveDetail",
                component: () =>
                  import("@/views/practice/practice-apply/approve/detail.vue")
              }
            ]
          },
          {
            path: "/school/practice/implement",
            name: "Implement",
            component: () =>
              import("@/views/practice/practice-apply/implement/index.vue"),
            children: [
              {
                path: "/school/practice/implement",
                name: "ImplementList",
                component: () =>
                  import("@/views/practice/practice-apply/implement/list.vue")
              }
            ]
          },
          {
            path: "/school/practice/project",
            name: "Project",
            component: () =>
              import("@/views/practice/practice-process/project/index.vue"),
            children: [
              {
                path: "/school/practice/project",
                name: "ProjectList",
                component: () =>
                  import("@/views/practice/practice-process/project/list.vue")
              },
              {
                path: "create",
                name: "CreateProject",
                component: () =>
                  import("@/views/practice/practice-process/project/create.vue")
              },
              {
                path: "edit",
                name: "EditProject",
                component: () =>
                  import("@/views/practice/practice-process/project/edit.vue")
              },
              {
                path: "view",
                name: "ViewProjectSchool",
                component: () =>
                  import("@/views/practice/practice-process/project/view.vue")
              }
            ]
          },
          {
            path: "/school/practice/inspect",
            name: "Inspect",
            component: () =>
              import("@/views/practice/practice-process/inspect/index.vue"),
            children: [
              {
                path: "/school/practice/inspect",
                name: "InspectList",
                component: () =>
                  import("@/views/practice/practice-process/inspect/list.vue")
              },
              {
                path: "create",
                name: "InspectCreate",
                component: () =>
                  import("@/views/practice/practice-process/inspect/create.vue")
              },
              {
                path: "edit",
                name: "InspectEdit",
                component: () =>
                  import("@/views/practice/practice-process/inspect/edit.vue")
              }
            ]
          },
          {
            path: "/school/practice/rewards",
            name: "Rewards",
            component: () =>
              import("@/views/practice/practice-process/rewards/index.vue"),
            children: [
              {
                path: "/school/practice/rewards",
                name: "RewardsList",
                component: () =>
                  import("@/views/practice/practice-process/rewards/list.vue")
              },
              {
                path: "create",
                name: "RewardsCreate",
                component: () =>
                  import("@/views/practice/practice-process/rewards/create.vue")
              },
              {
                path: "edit",
                name: "RewardsEdit",
                component: () =>
                  import("@/views/practice/practice-process/rewards/edit.vue")
              }
            ]
          },
          {
            path: "/school/practice/log",
            name: "Log",
            component: () =>
              import("@/views/practice/practice-process/daily-log/index.vue"),
            children: [
              {
                path: "/school/practice/log",
                name: "LogList",
                component: () =>
                  import("@/views/practice/practice-process/daily-log/list.vue")
              },
              {
                path: "edit",
                name: "EditLog",
                component: () =>
                  import("@/views/practice/practice-process/daily-log/edit.vue")
              },
              {
                path: "view",
                name: "ViewLog",
                component: () =>
                  import("@/views/practice/practice-process/daily-log/view.vue")
              }
            ]
          },
          {
            path: "/school/practice/monthStar",
            name: "monthStar",
            component: () =>
              import("@/views/practice/practice-process/monthStar/index.vue"),
            children: [
              {
                path: "/school/practice/monthStar",
                name: "monthStarList",
                component: () =>
                  import("@/views/practice/practice-process/monthStar/list.vue")
              },
              {
                path: "create",
                name: "monthStarCreate",
                component: () =>
                  import(
                    "@/views/practice/practice-process/monthStar/create.vue"
                  )
              },
              {
                path: "edit",
                name: "monthStarEdit",
                component: () =>
                  import("@/views/practice/practice-process/monthStar/edit.vue")
              },
              {
                path: "view",
                name: "monthStarView",
                component: () =>
                  import("@/views/practice/practice-process/monthStar/view.vue")
              }
            ]
          },
          {
            path: "/school/practice/attendance-approve",
            name: "AttendanceApprove",
            component: () =>
              import(
                "@/views/practice/practice-process/attendance-approve/index.vue"
              ),
            children: [
              {
                path: "/school/practice/attendance-approve",
                name: "AttendanceApproveList",
                component: () =>
                  import(
                    "@/views/practice/practice-process/attendance-approve/list.vue"
                  )
              }
            ]
          },
          {
            path: "/school/practice/internship-approve",
            name: "InternshipApprove",
            component: () =>
              import(
                "@/views/practice/practice-process/internship-approve/index.vue"
              ),
            children: [
              {
                path: "/school/practice/internship-approve",
                name: "InternshipApproveList",
                component: () =>
                  import(
                    "@/views/practice/practice-process/internship-approve/list.vue"
                  )
              }
            ]
          },
          {
            path: "/school/practice/attendance",
            name: "Attendance",
            component: () =>
              import("@/views/practice/practice-process/attendance/index.vue"),
            children: [
              {
                path: "/school/practice/attendance",
                name: "AttendanceList",
                component: () =>
                  import(
                    "@/views/practice/practice-process/attendance/list.vue"
                  )
              }
            ]
          },
          {
            path: "/school/practice/salary",
            name: "Salary",
            component: () =>
              import("@/views/practice/practice-process/salary/index.vue"),
            children: [
              {
                path: "/school/practice/salary",
                name: "SalaryList",
                component: () =>
                  import("@/views/practice/practice-process/salary/list.vue")
              }
            ]
          },
          {
            path: "/school/practice/evaluate",
            name: "Evaluate",
            component: () =>
              import("@/views/practice/assessment/evaluate/index.vue"),
            children: [
              {
                path: "/school/practice/evaluate",
                name: "EvaluateList",
                component: () =>
                  import("@/views/practice/assessment/evaluate/list.vue")
              },
              {
                path: "edit",
                name: "EditEvaluate",
                component: () =>
                  import("@/views/practice/assessment/evaluate/edit.vue")
              },
              {
                path: "view",
                name: "ViewEvaluate",
                component: () =>
                  import("@/views/practice/assessment/evaluate/view.vue")
              }
            ]
          },
          {
            path: "/school/practice/score",
            name: "Score",
            component: () =>
              import("@/views/practice/assessment/score/index.vue"),
            children: [
              {
                path: "/school/practice/score",
                name: "ScoreList",
                component: () =>
                  import("@/views/practice/assessment/score/list.vue")
              },
              {
                path: "edit",
                name: "EditScore",
                component: () =>
                  import("@/views/practice/assessment/score/edit.vue")
              },
              {
                path: "view",
                name: "ViewScore",
                component: () =>
                  import("@/views/practice/assessment/score/view.vue")
              }
            ]
          },
          {
            path: "/school/practice/summary",
            name: "Summary",
            component: () =>
              import("@/views/practice/assessment/summary/index.vue"),
            children: [
              {
                path: "/school/practice/summary",
                name: "SummaryList",
                component: () =>
                  import("@/views/practice/assessment/summary/list.vue")
              },
              {
                path: "edit",
                name: "EditSummary",
                component: () =>
                  import("@/views/practice/assessment/summary/edit.vue")
              },
              {
                path: "view",
                name: "ViewSummary",
                component: () =>
                  import("@/views/practice/assessment/summary/view.vue")
              }
            ]
          },
          {
            path: "/school/practice/file",
            name: "File",
            component: () => import("@/views/practice/record/file/index.vue"),
            children: [
              {
                path: "/school/practice/file",
                name: "FileList",
                component: () => import("@/views/practice/record/file/list.vue")
              }
              // {
              //   path: "view",
              //   name: "ViewFile",
              //   component: () =>
              //     import("@/views/practice/record/file/view.vue")
              // }
            ]
          },
          {
            path: "/school/practice/track",
            name: "Track",
            component: () => import("@/views/practice/record/track/index.vue"),
            children: [
              {
                path: "/school/practice/track",
                name: "TrackList",
                component: () =>
                  import("@/views/practice/record/track/list.vue")
              },
              {
                path: "view",
                name: "ViewTrack",
                component: () =>
                  import("@/views/practice/record/track/view.vue")
              }
            ]
          },
          {
            path: "/school/practice/signrule",
            name: "SignRuleSchool",
            component: () =>
              import("@/views/practice/practice-process/signrule/index.vue"),
            children: [
              {
                path: "/school/practice/signrule",
                name: "SignRuleListSchool",
                component: () =>
                  import("@/views/practice/practice-process/signrule/list.vue")
              },
              {
                path: "create",
                name: "CreateSignRuleSchool",
                component: () =>
                  import(
                    "@/views/practice/practice-process/signrule/create.vue"
                  )
              },
              {
                path: "edit",
                name: "EditSignRuleSchool",
                component: () =>
                  import("@/views/practice/practice-process/signrule/edit.vue")
              }
            ]
          },
          {
            path: "/school/practice/project",
            name: "ProjectEnterprise",
            component: () =>
              import("@/views/practice/practice-process/project/index.vue"),
            children: [
              {
                path: "/school/practice/project",
                name: "ProjectListSchool",
                component: () =>
                  import("@/views/practice/practice-process/project/list.vue")
              },
              {
                path: "create",
                name: "CreateProjectSchool",
                component: () =>
                  import("@/views/practice/practice-process/project/create.vue")
              },
              {
                path: "edit",
                name: "EditProjectSchool",
                component: () =>
                  import("@/views/practice/practice-process/project/edit.vue")
              },
              {
                path: "view",
                name: "ViewProjectSchool",
                component: () =>
                  import("@/views/practice/practice-process/project/view.vue")
              }
            ]
          },
          {
            path: "/school/student",
            name: "ListSchoolStudent",
            component: RouterView,
            meta: {
              title: "学生能力&档案"
            },
            children: [
              {
                path: "/school/student",
                name: "ListSchoolStudentIndex",
                component: () => import("@/views/school/student/list.vue"),
                meta: {
                  title: "学生管理"
                }
              },
              {
                path: "/school/student/record",
                name: "StudentRecord",
                component: () => import("@/views/school/student/record.vue"),
                meta: {
                  title: "档案",
                  isPublic: true
                }
              }
            ]
          },
          {
            path: "/school/ability",
            name: "ListSchoolAbility",
            component: RouterView,
            meta: {
              title: "学生能力"
            },
            children: [
              {
                path: "/school/ability",
                name: "ListSchoolAbilityIndex",
                component: () => import("@/views/school/ability/list.vue"),
                meta: {
                  title: "能力管理"
                }
              }
            ]
          },
          {
            path: "/school/ability",
            name: "ListSchoolAbilityIndex",
            component: () => import("@/views/school/ability/list.vue"),
            meta: {
              title: "能力管理"
            }
          }
        ]
      }
      // {
      //   path: "/school/student",
      //   component: () => import("@/components/SchoolLayout2.vue"),
      //   children: [
      //     {
      //       path: "/school/student",
      //       name: "ListSchoolStudent",
      //       component: RouterView,
      //       meta: {
      //         title: "学生能力&档案"
      //       },
      //       children: [
      //         {
      //           path: "/school/student",
      //           name: "ListSchoolStudentIndex",
      //           component: () => import("@/views/school/student/list.vue"),
      //           meta: {
      //             title: "学生管理"
      //           }
      //         },
      //         {
      //           path: "/school/student/record",
      //           name: "StudentRecord",
      //           component: () => import("@/views/school/student/record.vue"),
      //           meta: {
      //             title: "档案"
      //           }
      //         }
      //       ]
      //     },
      //     {
      //       path: "/school/ability",
      //       name: "ListSchoolAbility",
      //       component: RouterView,
      //       meta: {
      //         title: "学生能力"
      //       },
      //       children: [
      //         {
      //           path: "/school/ability",
      //           name: "ListSchoolAbilityIndex",
      //           component: () => import("@/views/school/ability/list.vue"),
      //           meta: {
      //             title: "能力管理"
      //           }
      //         }
      //       ]
      //     }
      //   ]
      // }
    ]
  },
  {
    path: "/agency",
    component: () => import("@/components/AgencyLayout.vue"),
    meta: {
      title: "广东轻工职业技术学院"
    },
    children: [
      {
        path: "/agency",
        name: "AgencyHome",
        meta: {
          title: "广东轻工职业技术学院"
        },
        component: () => import("@/views/agency/home.vue")
      },
      {
        path: "enterprise",
        name: "AgencyentErprise",
        component: () => import("@/views/agency/enterprise.vue")
      },
      {
        path: "school",
        name: "AgencyentSchool",
        component: () => import("@/views/agency/school.vue")
      },
      {
        path: "order",
        name: "AgencyOrder",
        component: () => import("@/views/order/list.vue")
      },
      {
        path: "favorite",
        name: "AgencyFavorite",
        component: () => import("@/views/favorite/enterprise.vue")
      }
    ]
  },
  {
    path: "/admin",
    component: () => import("@/components/AdminLayout.vue"),
    meta: {
      title: "广东轻工职业技术学院"
    },
    children: [
      {
        path: "/admin",
        name: "AdminHome",
        meta: {
          title: "广东轻工职业技术学院"
        },
        component: () => import("@/views/admin/home.vue")
      },
      {
        path: "enterprise",
        name: "AdminErprise",
        component: () => import("@/views/admin/enterprise.vue")
      },
      {
        path: "school",
        name: "AdminSchool",
        component: () => import("@/views/admin/school.vue")
      }
    ]
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/register/index.vue")
  },
  {
    path: "/student",
    component: () => import("@/components/StudentLayout.vue"),
    meta: {
      title: "广东轻工职业技术学院"
    },
    children: [
      {
        path: "/student",
        name: "StudentHome",
        meta: {
          title: "个人中心"
        },
        component: () => import("@/views/student/home.vue")
      }
      // {
      //   path: "/student/job",
      //   component: () => import("@/views/job/index.vue"),
      //   children: [
      //     {
      //       path: "/student/job",
      //       name: "ListJobStudent",
      //       component: () => import("@/views/job/list.vue"),
      //       meta: {
      //         authorization: true,
      //         title: "岗位管理"
      //       }
      //     },
      //     {
      //       path: "create",
      //       name: "CreateJob",
      //       component: () => import("@/views/job/create.vue"),
      //       meta: {
      //         authorization: true,
      //         title: "岗位管理"
      //       }
      //     },
      //   ]
      // }
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index.vue")
  },
  {
    path: "/authenticate",
    name: "EnterpriseAuthenticate",
    component: () => import("@/views/enterprise/authenticate.vue"),
    meta: {
      authorization: true
    }
  },
  {
    path: "/certification",
    name: "SchoolCertification",
    component: () => import("@/views/school/certification.vue"),
    meta: {
      authorization: true
    }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = new VueRouter({
  // https://router.vuejs.org/zh/guide/advanced/scroll-behavior.html#%E5%BC%82%E6%AD%A5%E6%BB%9A%E5%8A%A8
  // 返回savedPosition 其实就是 当用户点击 返回的话，保持之前浏览的高度
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop;
      }
      return {
        x: 0,
        y: to.meta.savedPosition || 0
      };
    }
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
