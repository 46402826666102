import request from "@/utils/request";
const controller = "/User";
const controller2 = "/UserInfo";

export function GetUserInfo(id) {
  return request({
    url: `${controller}/GetInfo`,
    method: "get",
    params: {
      id
    }
  });
}

export function UpdateUserInfo(data) {
  return request({
    url: `${controller}/Update`,
    method: "post",
    data
  });
}

const getStaffInfo = id =>
  request({
    url: `${controller2}/GetStaffInfo`,
    method: "get",
    params: { id }
  });
const changePassword = data =>
  request({
    url: `${controller}/ChangePassword`,
    method: "post",
    data
  });

export { getStaffInfo,changePassword };

// // 登录参数
// const login_config = {
//   client_id: "Zhilin.IC.App",
//   client_secret: "secret",
//   grant_type: "password",
//   username: "",
//   password: "",
//   scope: "openid profile offline_access"
// };
// const refresh_config = {
//   client_id: "Zhilin.IC.App",
//   client_secret: "secret",
//   grant_type: "refresh_token",
//   refresh_token: ""
// };

// import axios from "axios";
// import qs from "qs";
// /*
// 登录接口 POST
// @params Object
// username
// password
// */
// export function login(data) {
//   let postData = {
//     ...login_config,
//     ...data
//   };
//   return axios.request({
//     url: "https://user.ve-city.com/connect/token",
//     //为确保兼容性 method务必大写
//     method: "POST",
//     data: qs.stringify(postData),
//     headers: {
//       "content-type": "application/x-www-form-urlencoded"
//     }
//   });
// }

// export function refreshToken(data) {
//   let postData = {
//     ...refresh_config,
//     ...data
//   };
//   return axios.request({
//     url: "https://user.ve-city.com/connect/token",
//     //为确保兼容性 method务必大写
//     method: "POST",
//     data: qs.stringify(postData),
//     headers: {
//       "content-type": "application/x-www-form-urlencoded"
//     }
//   });
// }

// export function getInfo(token) {
//   return axios.request({
//     url: "https://user.ve-city.com/connect/userinfo",
//     method: "GET",
//     header: {
//       "content-type": "application/x-www-form-urlencoded",
//       Authorization: token
//     }
//   });
// }
