import Cookies from "js-cookie";
// import Mgr from "../services/SecurityService";

const TokenKey = "Admin-Token";

const InfoKey = "CurrentThreePartInfo";
// var user = new Mgr();

export function getToken() {
  throw Error("已弃用");
  // return user.getAccessToken();
}

export function setToken(token) {
  Cookies.set("Base-Api", process.env.VUE_APP_BASE_API);
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function setCurrentInfo(info) {
  // console.log(info);
  sessionStorage.setItem(InfoKey, JSON.stringify(info));
  return true;
}

export function getCurrentInfo() {
  let info = sessionStorage.getItem(InfoKey);
  // console.log(999, info);
  if (info) {
    return JSON.parse(info);
  }
  return null;
}
