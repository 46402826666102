<script>
export default {
  name: "lemonMessageJob",
  inheritAttrs: false,
  inject: ["IMUI"],
  render() {
    return (
      <lemon-message-basic
        class="lemon-message-job"
        props={{ ...this.$attrs }}
        scopedSlots={{
          content: props => <span>{props.content}</span>
        }}
      />
    );
  }
};
</script>
<style lang="scss" scoped>
.lemon-message-job {
  /deep/ .lemon-message__content {
    background: #fff;
    border: 1px solid #598DF3;
    :hover{
      cursor: pointer;
    }
  }
}
</style>