const SET_INDUSTRY = 'SET_INDUSTRY';
const SET_SCALE = 'SET_SCALE';
const SET_NATURE = 'SET_NATURE';
const SET_EXPERIENCE = 'SET_EXPERIENCE';
const SET_EDUCATION = 'SET_EDUCATION';
const SET_SALARY = 'SET_SALARY';

const SET_AREA = 'SET_AREA';
const SET_AREA_TREE = 'SET_AREA_TREE';

const SET_JOB_CATEGORY = 'SET_JOB_CATEGORY';
const SET_JOB_CATEGORY_TREE = 'SET_JOB_CATEGORY_TREE';

const SET_MAJOR = 'SET_MAJOR';
const SET_MAJOR_TREE = 'SET_MAJOR_TREE';

const SET_ABILITY = 'SET_ABILITY';

export {
  SET_INDUSTRY,
  SET_SCALE,
  SET_NATURE,
  SET_EXPERIENCE,
  SET_EDUCATION,
  SET_SALARY,
  SET_AREA,
  SET_AREA_TREE,
  SET_JOB_CATEGORY,
  SET_JOB_CATEGORY_TREE,
  SET_MAJOR,
  SET_MAJOR_TREE,
  SET_ABILITY
}