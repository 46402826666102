import router from "@/router";
import store from "@/store";
import { Message } from "element-ui";
// import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css' // progress bar style
import { getStaffInfo } from '@/api/user';
NProgress.configure({
  showSpinner: false
}); // NProgress Configuration

// router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidc"));
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + ' - 广东轻工职业技术学院'
  } else {
    document.title = '广东轻工职业技术学院'
  }
  NProgress.start();
  store.dispatch('oidc/oidcCheckAccess', to).then(async hasAccess => {
    if (to.meta && to.meta.isOidcCallback) {
      next();
    } else {
      if (hasAccess) {
        if (store.getters['oidc/oidcIsAuthenticated'] && store.getters['employee'] === undefined) {
          try {
            const { Result } = await getStaffInfo(store.getters['userId']);
            store.dispatch('user/initStaffInfo', Result);
            next();
          } catch (error) {
            NProgress.done();
            Message.error('加载失败，请稍后重试');
          }
        } else {
          next();
        }
      }
    }
  });
});

router.afterEach(_ => NProgress.done());