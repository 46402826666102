import request from '@/utils/request'
const controller = '/Ability'

const getAbilityList = _ =>
  request({
    url: `${controller}/GetAbilityList`,
    method: 'get'
  });
  // 种类
const getCategoryInfo = id =>
  request({
    url: `${controller}/GetCategoryInfo?id=${id}`,
    method: 'get'
  });
const insertCategory = data =>
  request({
    url: `${controller}/InsertCategory`,
    method: 'post',
    data
  });
const updateCategory = data =>
  request({
    url: `${controller}/UpdateCategory`,
    method: 'post',
    data
  });
const deleteCategory = id =>
  request({
    url: `${controller}/DeleteCategory?id=${id}`,
    method: 'post'
  });
const getContentInfo = id =>
  request({
    url: `${controller}/GetContentInfo?id=${id}`,
    method: 'get'
  });
const insertContent = data =>
  request({
    url: `${controller}/InsertContent`,
    method: 'post',
    data
  });
const updateContent = data =>
  request({
    url: `${controller}/UpdateContent`,
    method: 'post',
    data
  });
const deleteContent = id =>
  request({
    url: `${controller}/DeleteContent?id=${id}`,
    method: 'post'
  });
export {
  getAbilityList,
  getCategoryInfo,
  insertCategory,
  updateCategory,
  deleteCategory,
  getContentInfo,
  insertContent,
  updateContent,
  deleteContent
}