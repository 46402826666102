import { MessageStatusEnum, MessageStatusStringEnum } from '@/utils/constant'

const state = {
  failedMessageList: [],
  receivedMessageList: []
}

const mutations = {
  ADD_FAILED_MESSAGE: (state, message) => {
    state.failedMessageList.push(message);//= [...state.failedMessageList, message];
  },
  REMOVE_FAILED_MESSAGE: (state, id) => {
    state.failedMessageList = state.failedMessageList.filter(item => item.id !== id);
  },
  ADD_RECEIVED_MESSAGE: (state, message) => {
    state.receivedMessageList.push(message);
  },
  CLEAR_RECEIVED_MESSAGE: (state) => {
    state.receivedMessageList = [];
  },
}

const actions = {
  addFailedMessage({ commit }, message) {
    commit('ADD_FAILED_MESSAGE', { ...message, status: MessageStatusStringEnum[MessageStatusEnum.failed] });
  },
  removeFailedMessage({ commit }, messageId) {
    commit('REMOVE_FAILED_MESSAGE', messageId);
  },
  addReceivedMessage({ commit }, message) {
    commit('ADD_RECEIVED_MESSAGE', message)
  },
  clearReceivedMessage({ commit }) {
    commit('CLEAR_RECEIVED_MESSAGE');
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}