import Vue from 'vue'
import Vuex from 'vuex'

// import createPersistedState from "vuex-persistedstate";
// import SecureLS from "secure-ls";

import getters from './getters';
import oidc from './modules/oidc';
import user from './modules/user';
import course from './modules/course';
import chat from './modules/chat';
import common from './modules/common';
import threepart from './modules/threepart';


// // https://webpack.js.org/guides/dependency-management/#requirecontext
// const modulesFiles = require.context('./modules', false, /\.js$/)

// // you do not need `import app from './modules/app'`
// // it will auto require all vuex module from modules file
// const modules = modulesFiles.keys().reduce((modules, modulePath) => {
//   // set './app.js' => 'app'
//   const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
//   const value = modulesFiles(modulePath)
//   modules[moduleName] = value.default
//   return modules
// }, {})

// var ls = new SecureLS({ encodingType: '', isCompression: false });
Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    oidc,
    user,
    course,
    chat,
    common,
    threepart
  },
  getters,
  // plugins: [createPersistedState({
  //   key :'route',
  //   // storage: window.sessionStorage,
  //   storage: {
  //     getItem: (key) => ls.get(key),
  //     setItem: (key, value) => ls.set(key, value),
  //     removeItem: (key) => ls.remove(key),
  //   },
  //   reducer(val) {
  //     console.log('aaa', val);
  //     return {
  //       // 只储存state中的user
  //       route: val.route
  //     }
  //   }
  // })]
})

export default store
