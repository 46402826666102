import { getCurrentInfo } from "@/utils/auth";

const state = {
  currentId: getCurrentInfo()?.id,
  currentType: getCurrentInfo()?.type,
  currentInfo: getCurrentInfo()
};

const mutations = {
  SET_VALUE(state, params) {
    state[params.key] = params.value;
  }
};

const actions = {
  setCurrentInfo({ commit }, currentInfo) {
    return new Promise(resolve => {
      commit("SET_VALUE", {
        key: "currentId",
        value: currentInfo.id
      });
      commit("SET_VALUE", {
        key: "currentType",
        value: currentInfo.type
      });
      commit("SET_VALUE", {
        key: "currentInfo",
        value: currentInfo
      });
      resolve();
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
