import router from "@/router";
import { SET_EMPLOYEE } from '../mutation/user-mutation';
import { StaffTypeEnum } from '@/utils/constant';

const state = {
  employee: undefined
};

const mutations = {
  [SET_EMPLOYEE]: (state, employee) => {
    state.employee = employee;
  }
};
const getters = {
  getCurrentStaffByType: ({ employee }) => staffType => employee ? employee.find(x => x.StaffType === staffType) : null,
  getCurrentStaff: ({ employee }) => {
    const { matched } = router.currentRoute;
    if (matched.some(({ path }) => path.includes('enterprise'))) {
      return employee ? employee.find(x => x.StaffType === StaffTypeEnum.Enterprise) : null;
    } else if (matched.some(({ path }) => path.includes('school'))) {
      return employee ? employee.find(x => x.StaffType === StaffTypeEnum.School) : null;
    }
    return null;
  }
}
const actions = {
  initStaffInfo({ commit }, employee) {
    commit(SET_EMPLOYEE, employee);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
