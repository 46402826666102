const arrayToTreeWithLeafChild = (treeArray, rootIdValue = null, idProp = 'Id', parentIdProp = 'ParentId', childProp = 'Children') => {
	const result = [];
	const itemMap = {};
	for (const item of treeArray) {
		const id = item[idProp];
		const pid = item[parentIdProp];
		itemMap[id] = {
			...item,
			[childProp]: itemMap[id] ? itemMap[id][childProp] || [] : []
		}
		const treeItem = itemMap[id];
		if (pid === rootIdValue) {
			result.push(treeItem);
		} else {
			if (!itemMap[pid]) {
				itemMap[pid] = {
					[childProp]: [],
				}
			}
			itemMap[pid][childProp].push(treeItem)
		}
	}
	return result;
};
const arrayToTree = (data,idProp = 'Id', parentIdProp = 'ParentId', childProp = 'Children') => {
	let result = [];
	if (!Array.isArray(data)) {
		return result;
	}
	
	let map = {};
	data.forEach(item => {
		delete item[childProp];
		map[item[idProp]] = item;
	});
	
	data.forEach(item => {
		let parent = map[item[parentIdProp]];
		if (parent) {
			(parent[childProp] || (parent[childProp] = [])).push(item);
		} else {
			result.push(item);
		}
	});
	return result;
};
export {
	arrayToTreeWithLeafChild,
	arrayToTree
}