import {
  getEnterpriseCondition,
  getJobCondition,
  getAreaList,
  getJobCategoryList,
  getMajorList
} from "@/api/common";
import { getAbilityList } from "@/api/ability";
//todo:后续考虑拆分接口，分别获取
// import { getSalaryList, getExperienceList, getEducationList } from '@/api/common';
import {
  SET_INDUSTRY,
  SET_SCALE,
  SET_NATURE,
  SET_EXPERIENCE,
  SET_EDUCATION,
  SET_SALARY,
  SET_AREA,
  SET_AREA_TREE,
  SET_JOB_CATEGORY,
  SET_JOB_CATEGORY_TREE,
  SET_MAJOR,
  SET_MAJOR_TREE,
  SET_ABILITY
} from "../mutation/common-mutation";

import { arrayToTreeWithLeafChild } from "@/utils/util";

const state = {
  Industry: [],
  Scale: [],
  Nature: [],
  Experience: [],
  Education: [],
  Salary: [],
  Area: [],
  AreaTree: [],
  JobCategory: [],
  JobCategoryTree: [],
  Major: [],
  MajorTree: [],
  Ability: []
};

const mutations = {
  [SET_INDUSTRY]: (state, industry = []) => {
    state.Industry = industry;
  },
  [SET_SCALE]: (state, scale = []) => {
    state.Scale = scale;
  },
  [SET_NATURE]: (state, nature = []) => {
    state.Nature = nature;
  },
  [SET_EXPERIENCE]: (state, experience = []) => {
    state.Experience = experience;
  },
  [SET_EDUCATION]: (state, education = []) => {
    state.Education = education;
  },
  [SET_SALARY]: (state, salary = []) => {
    state.Salary = salary;
  },
  [SET_AREA]: (state, area = []) => {
    state.Area = area;
  },
  [SET_AREA_TREE]: (state, area = []) => {
    state.AreaTree = area;
  },
  [SET_JOB_CATEGORY]: (state, jobCategory = []) => {
    state.JobCategory = jobCategory;
  },
  [SET_JOB_CATEGORY_TREE]: (state, jobCategoryTree = []) => {
    state.JobCategoryTree = jobCategoryTree;
  },
  [SET_MAJOR]: (state, major = []) => {
    state.Major = major;
  },
  [SET_MAJOR_TREE]: (state, majorTree = []) => {
    state.MajorTree = majorTree;
  },
  [SET_ABILITY]: (state, ability = []) => {
    state.Ability = ability;
  }
};

const actions = {
  init({ dispatch }) {
    dispatch("initEnterpriseCondition");
    dispatch("initJobCondition");
    dispatch("initArea");
    dispatch("initJobCategory");
    dispatch("initMajor");
    dispatch("initAbility");
  },
  initEnterpriseCondition({ commit }) {
    getEnterpriseCondition().then(
      ({ Result: { Industry = [], Nature = [], Scale = [] } = {} } = {}) => {
        commit(SET_INDUSTRY, Industry);
        commit(SET_NATURE, Nature);
        commit(SET_SCALE, Scale);
      }
    );
  },
  initJobCondition({ commit }) {
    getJobCondition().then(
      ({
        Result: { Experience = [], Education = [], Salary = [] } = {}
      } = {}) => {
        commit(SET_EXPERIENCE, Experience);
        commit(SET_EDUCATION, Education);
        commit(SET_SALARY, Salary);
      }
    );
  },
  initArea({ commit }) {
    getAreaList().then(({ Result }) => {
      commit(SET_AREA, Object.freeze(Result));
      commit(
        SET_AREA_TREE,
        Object.freeze(
          arrayToTreeWithLeafChild(Result, "000000", "Code", "ParentCode")
        )
      );
    });
  },
  initJobCategory({ commit }) {
    getJobCategoryList().then(({ Result }) => {
      commit(SET_JOB_CATEGORY, Object.freeze(Result));
      commit(
        SET_JOB_CATEGORY_TREE,
        Object.freeze(arrayToTreeWithLeafChild(Result))
      );
    });
  },
  initMajor({ commit }) {
    getMajorList().then(({ Result }) => {
      commit(SET_MAJOR, Object.freeze(Result));
      commit(SET_MAJOR_TREE, Object.freeze(arrayToTreeWithLeafChild(Result)));
    });
  },
  initAbility({ commit }) {
    getAbilityList().then(({ Result }) => {
      commit(SET_ABILITY, Object.freeze(Result));
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
