<template>
  <div id="app" :class="isFull ? '' : 'content-full'">
    <router-view />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { SignalrUrl } from "./utils/constant";
const signalR = require("@microsoft/signalr");
let connection = null;
export default {
  computed: {
    ...mapGetters(["token"]),
    isFull() {
      if (this.$route.path.indexOf("visual_analysis") == -1) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    ...mapActions("chat", ["addReceivedMessage"]),
    ...mapActions("oidc", ["signOutOidc"]),
    ...mapActions("common", ["init"]),
    userLoaded() {
      console.log("app.vue :vuexoidc:userLoaded");
    },
    userSignedOut() {
      this.signOutOidc();
    },
    initSignalR() {
      let that = this;
      connection = new signalR.HubConnectionBuilder()
        .withUrl(SignalrUrl, { accessTokenFactory: () => this.token })
        .configureLogging(signalR.LogLevel.Error)
        .withAutomaticReconnect([0, 5000, 15000, 30000, 45000, 60000])
        .build();
      connection.onclose(_ =>
        connection
          .start()
          .then(_ => console.log("重新连接成功"))
          .catch(err => console.log(err))
      );
      connection.on("ReceviedMessage", data => {
        console.log("收到消息", data);
        this.addReceivedMessage(data);
        if (this.$route.name !== "EnterpriseChat") {
          const n = this.$notify({
            title: "收到一条新消息",
            message: data.Message,
            onClick: () => {
              that.$router.push(`/enterprise/chat`);
              n.close();
            }
          });
        }
      });
    }
  },
  created() {
    this.init();
    // this.initSignalR();
    window.addEventListener("vuexoidc:userLoaded", this.userLoaded);
    // window.addEventListener("vuexoidc:userSignedOut", this.userSignedOut);
  },
  destroyed() {
    // connection && connection.stop();
    window.removeEventListener("vuexoidc:userLoaded", this.userLoaded);
    window.removeEventListener("vuexoidc:userSignedOut", this.userSignedOut);
  },
  watch: {
    token: {
      handler(val) {
        // if (val) {
        //   if (connection && connection.state === signalR.HubConnectionState.Disconnected) {
        //     connection.start().catch(err => console.log(err))
        //   }
        // } else {
        //   connection && connection.stop();
        // }
      },
      immediate: true
    }
  }
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
// 全局设置
body .el-table th.gutter {
  display: table-cell !important;
}

body .el-table colgroup.gutter {
  display: table-cell !important;
}
#app {
  font-family: "PingFang SC", "Helvetica Neue", Helvetica, "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
}
.content-full {
  background-color: #07062b;
  overflow-y: auto;
  // min-height: 1080px;
  &::-webkit-scrollbar {
    width: 0px !important;
  }
}
</style>
