const state = {
   courseEditing:false,
   finishedEditing:true,
}
const mutations={
  Set_FinishedEditing: (state, value) => {
    state.finishedEditing = value
  },
  Set_CourseEditing: (state, value) => {
    state.courseEditing = value
  },
}
const actions={

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}