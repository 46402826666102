/**在线沟通地址 */
const SignalrUrl = process.env.VUE_APP_SIGNALR_URL;
/**统一认证中心配置 */
const OidcConfig = JSON.parse(process.env.VUE_APP_OIDC_CONFIG);
/**用户中心地址 */
const AccountUrl = process.env.VUE_APP_ACCOUNT_URL;
/**简历投递状态 */
const ResumeDeliveryStatusEnum = {
  /**已投递 */
  Delivery: 0,
  /**加候选 */
  Candidate: 1,
  /**面试 */
  Interview: 2,
  /**不合适 */
  NotSuitable: 3,
  /**录取 */
  Admit: 4,
  /**归档 */
  Archive: 5
};
/**员工类型 */
const StaffTypeEnum = {
  /**学校员工 */
  School: 1,
  /**企业员工 */
  Enterprise: 2,
  /**个人 */
  Personal: 3
};
/**审批状态 */
const AuditStatusEnum = {
  /**草稿/未提交 */
  Draft: 0,
  /**审核中/已提交 */
  Submitted: 1,
  /**已通过/已同意 */
  Passed: 2,
  /**未通过/已拒绝 */
  Refused: 3
};
/**校企合作类型 */
const CooperationTypeEnum = {
  /**校企合作 */
  SchoolToEnterprise: 0,
  /**企校合作 */
  EnterpriseToSchool: 1,
  /**企企合作 */
  EnterpriseToEnterprise: 2
};
/**岗位推送审批状态 */
const RecruitJobPushSchoolStatusEnum = {
  /**待审批 */
  Running: 0,
  /**通过 */
  Approved: 1
};
const RecruitJobSourceTypeEnum = {
  /**学校发布的岗位 */
  SchoolPublish: 0,
  /**企业推送的岗位 */
  EnterprisePush: 1
};
const BannerTypeEnum = {
  /**Pc首页 */
  PcHome: 0,
  /**手机端首页 */
  MobileHome: 1,
  /**手机端课程 */
  MobileCourse: 2,
  /**手机端发现 */
  MobileDiscovery: 3
};

const RecruitStatusEnum = {
  /**上线 */
  Online: 0,
  /**下线 */
  Offline: 1
};
const ChatMessageTypeEnum = {
  //todo:为了适配聊天的消息格式都小写
  /**普通文本（包含emoji） */
  text: 0,
  /**图片 */
  image: 1,
  /**链接 */
  link: 2,
  /**岗位（发送的岗位） */
  job: 3,
  /**简历（发送的简历） */
  resume: 4,
  /**面试 */
  interview: 5,
  /**文件 */
  file: 6,
  /**事件 */
  event: 7
};
const ChatMessageTypeStringEnum = {
  /**普通文本（包含emoji） */
  [ChatMessageTypeEnum.text]: "text",
  /**图片 */
  [ChatMessageTypeEnum.image]: "image",
  /**链接 */
  [ChatMessageTypeEnum.link]: "link",
  /**岗位（发送的岗位） */
  [ChatMessageTypeEnum.job]: "job",
  /**简历（发送的简历） */
  [ChatMessageTypeEnum.resume]: "resume",
  /**面试 */
  [ChatMessageTypeEnum.interview]: "interview",
  /**文件 */
  [ChatMessageTypeEnum.file]: "file",
  /**事件 */
  [ChatMessageTypeEnum.event]: "event"
};

const UserTypeEnum = {
  /**求职者 */
  JobSeeker: 0,
  /**招聘者 */
  JobRecruiter: 1
};

const ShareTypeEnum = {
  /// qrcode
  QrCode: 0,
  /// 链接
  UrlLink: 1,
  /// 码和链接
  Both: 2
};
const GenderEnum = {
  /**未知 */
  None: 0,
  /**男 */
  Male: 1,
  /**女 */
  Female: 2
};
const InterviewTypeEnum = {
  /**线上 */
  Online: 0,
  /**线下 */
  Offline: 1
};
const MessageStatusEnum = {
  /**发送成功 */
  succeed: 0,
  /**发送失败 */
  failed: 1,
  /**发送中 */
  going: 2
};
const MessageStatusStringEnum = {
  /**发送成功 */
  [MessageStatusEnum.succeed]: "succeed",
  /**发送失败 */
  [MessageStatusEnum.failed]: "failed",
  /**发送中 */
  [MessageStatusEnum.going]: "going"
};
const RecruitTypeEnum = {
  /**全职 */
  FullTime: 0,
  /**兼职 */
  PartTime: 1
};

const RecruitNatureEnum = {
  /**全职 */
  Social: 0,
  /**兼职 */
  School: 1
};
const ShareLink = {
  /**开发环境 */
  Dev: "http://beta.gqg.ve-city.com/app",
  /**正式环境 */
  Prod: "https://gqg.ve-city.com/app"
};

export {
  SignalrUrl,
  OidcConfig,
  AccountUrl,
  ResumeDeliveryStatusEnum,
  StaffTypeEnum,
  AuditStatusEnum,
  CooperationTypeEnum,
  RecruitJobPushSchoolStatusEnum,
  RecruitJobSourceTypeEnum,
  BannerTypeEnum,
  RecruitStatusEnum,
  ChatMessageTypeEnum,
  ChatMessageTypeStringEnum,
  UserTypeEnum,
  ShareTypeEnum,
  GenderEnum,
  InterviewTypeEnum,
  MessageStatusEnum,
  MessageStatusStringEnum,
  RecruitTypeEnum,
  RecruitNatureEnum,
  ShareLink
};
