import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/styles/index.scss";
import "@/assets/iconfont/iconfont.css";
import "font-awesome/scss/font-awesome.scss";
import "./permission";
import ZhilinCMS from "zhilin-cms";
import axios from "./utils/request";
import { sync } from "vuex-router-sync";
import VueAliplayerV2 from "vue-aliplayer-v2";

import LemonIMUI from "lemon-imui";
import "lemon-imui/dist/index.css";
import LemonMessageJob from "@/views/chat/components/lemon-message-job.vue";
import LemonMessageInterview from "@/views/chat/components/lemon-message-interview.vue";
import LemonMessageResume from "@/views/chat/components/lemon-message-resume.vue";
Vue.use(LemonIMUI);
Vue.component(LemonMessageJob.name, LemonMessageJob);
Vue.component(LemonMessageInterview.name, LemonMessageInterview);
Vue.component(LemonMessageResume.name, LemonMessageResume);

import directives from "@/utils/directives.js"; // vue全局指令
Vue.use(directives);

Vue.use(VueAliplayerV2);

Vue.use(ZhilinCMS);
Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.use(ElementUI);

sync(store, router);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
