import request from '@/utils/request'
const controller = '/InterviewRecord'

const getInterview = id =>
  request({
    url: `${controller}/GetInfo?id=${id}`,
    method: 'get'
  });
export {
  getInterview
}