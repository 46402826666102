
<!--
<template>
  <lemon-message-basic class="lemon-message-interview" v-bind="{ ...$attrs }">
    <template #content="props">
      <span>{{props.content}}&nbsp;🔈1</span>
    </template>
  </lemon-message-basic>
</template>
-->

<script>
import { getInterview } from '@/api/interviewrecord';
export default {
  name: "lemonMessageInterview",
  inheritAttrs: false,
  inject: ["IMUI"],
  data() {
    return {
      interview: null
    }
  },
  mounted() {
    const { TargetId } = this.$attrs.message || {};
    getInterview(TargetId).then(({ Result }) => {
      this.interview = Result
    });
  },
  render() {
    return (
      <lemon-message-basic
        class="lemon-message-interview"
        props={{ ...this.$attrs }}
        scopedSlots={{
          content: props => <span>{props.content}</span>
        }}
      />
    );
  }
};
</script>
<style lang="scss" scoped>
.lemon-message-interview {
  /deep/ .lemon-message__content {
    background: #fff;
    border: 1px solid #598DF3;
  }
}
</style>