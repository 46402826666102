import { vuexOidcCreateStoreModule } from 'vuex-oidc'
// import { WebStorageStateStore, Log } from 'oidc-client'
import { setToken, removeToken } from '@/utils/auth';
import { OidcConfig } from '@/utils/constant';
// import { SecureLocalStorage } from '@/utils/storage';
// if (process.env.NODE_ENV === "development") {
// Log.logger = console;
// Log.level = Log.DEBUG;
// }
const oidcStore = vuexOidcCreateStoreModule({
  ...OidcConfig,
  // userStore: new WebStorageStateStore({ store: new SecureLocalStorage() })
}, {
  namespaced: true,
  dispatchEventsOnWindow: true
},
  {
    userLoaded: (user) => {
      setToken(`Bearer ${user.access_token}`);
    },
    userUnloaded: () => { },
    accessTokenExpiring: () => { },
    accessTokenExpired: () => removeToken(),
    silentRenewError: () => { },
    userSignedOut: () => removeToken(),
    oidcError: () => { },
    automaticSilentRenewError: () => { }
  }
)



export default oidcStore;