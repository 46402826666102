const getters = {
  token: state => state.oidc.access_token,
  user: state => state.oidc.user,

  nickname: state => state.oidc.user?.NickName,
  userId: state => state.oidc.user?.Id,
  avatar: state => state.oidc.user?.Avatar,
  name: state => state.oidc.user?.UserName,

  sid: state => state.oidc.user?.SId,
  oid: state => state.oidc.user?.OId,

  employee: state => state.user.employee,

  receivedMessageList: state => state.chat.receivedMessageList,

  userInfo: state => state.user.userInfo,
  currentChatId: state => state.chat.currentChatId,
  messageList: state => state.chat.messageList,
  currentId: state => state.threepart.currentId,
  currentType: state => state.threepart.currentType,
  currentInfo: state => state.threepart.currentInfo
};
export default getters;
